import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import VideoCircle from "../../components/VideoCircle/VideoCircle";
import "./ThankYouPage.css";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Slide from "../../components/Slide/Slide";

const TITLE = "Please listen to the full introduction before you get started.";
const DESCRIPTION = "This will only take 5mins and we will cover how to use the experience and what to expect from this Ocado in Store Fulfillment session.";
const useStyles = makeStyles(() => ({
  title: {
    fontSize: 75,
    fontWeight: "bold",
    color: "white",
    textAlign: "center"

  },
  subtitle: {
    fontSize: 75,
    fontWeight: "bold",
    color: "#00bbf4",
    textAlign: "center",
    lineHeight: 1.3
  },
  startButton: {
    backgroundColor: "#FF1A68",
    color:"#ffff",
    width: 190,
    height: 45,
    marginTop: 52,
    marginRight: "auto",
    marginLeft: "auto",
    fontSize: 17,
    fontWeight: "bold",
    borderRadius: 40,
    border: '0',
    cursor: 'pointer'
  },
}));
export default function Welcome(props) {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const { t: tUnity } = useTranslation("unity");
  return (
    <div className="app-parent">
      <Navbar />
      <div class="welcome">
        <h1 align="center">
          <span className={classes.title}>Thank</span>
          <span className={classes.subtitle}> You</span>
        </h1>
        <div>
            <a href="/">
                <button className={classes.startButton}>Restart</button>
            </a>
        </div>
      </div>
      <Footer />
   
      
    </div>
  );
}
