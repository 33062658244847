import { createContext, useReducer, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


const defaultState = null;

const AuthContext = createContext(null);

function authReducer(state, action) {
    switch (action.type) {
        case 'setPasscode':
            sessionStorage.setItem('passcode', action.payload);
            return action.payload;
        case 'removePasscode':
            sessionStorage.clear();
            return null;
    }

}

function AuthProvider({ children }) {
    const navigate = useNavigate();
    const getDefaultState = () => sessionStorage.getItem('passcode');
    const [state, dispatch] = useReducer(authReducer, defaultState, getDefaultState);

    useEffect(() => {
        if (!state) {
            navigate('/login');
        }

    }, [state])

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth };