/* eslint-disable max-len */

import { SVGProps } from "react";

export default function OcadoLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width="200" viewBox="0 0 653.23 269.38" {...props}>
      <path d="M488.22,89.42c-20.8,0-37.14,16-37.14,37s16.34,37,37.14,37,37.12-16,37.12-37-16.33-37-37.12-37m0,12.78c13.22,0,23.77,11,23.77,24.22,0,13.06-10.55,24.21-23.77,24.21s-23.76-11.15-23.76-24.21c0-13.23,10.54-24.22,23.76-24.22"/>
      <path d="M316.41,150.62c-13.22,0-23.76-11.14-23.76-24.21,0-13.22,10.54-24.21,23.76-24.21s23.77,11,23.77,24.21c0,13.07-10.54,24.21-23.77,24.21m23.53-59.13v7.94h-.48c-5.42-6.08-14.23-10-23.41-10-20.8,0-37.13,16-37.13,37s16.33,37,37.13,37a32.33,32.33,0,0,0,23.41-10h.48v7.77H353.3V91.49Z"/>
      <path d="M402.31,150.62c-13.22,0-23.77-11.14-23.77-24.21,0-13.22,10.55-24.21,23.77-24.21a24,24,0,0,1,23.79,24.21c0,13.07-10.57,24.21-23.79,24.21m23.51-92.46V99.43h-.49c-5.4-6.08-14.21-10-23.39-10-20.79,0-37.12,16-37.12,37s16.33,37,37.12,37a32.25,32.25,0,0,0,23.39-10h.49v7.77h13.37v-103Z"/>
      <path d="M266.79,143a23.43,23.43,0,0,1-17.29,7.71c-13.22,0-23.75-11.15-23.75-24.21,0-13.23,10.53-24.22,23.75-24.22A23.32,23.32,0,0,1,266.79,110l6.47-12.15a37.22,37.22,0,0,0-23.76-8.3c-20.8,0-37.12,16-37.12,37s16.32,37,37.12,37a37.29,37.29,0,0,0,23.76-8.31Z"/>
      <path d="M165,89.42c-20.79,0-37.11,16-37.11,37s16.32,37,37.11,37,37.14-16,37.14-37-16.35-37-37.14-37m0,12.78a24,24,0,0,1,23.79,24.22c0,13.06-10.55,24.21-23.79,24.21s-23.75-11.15-23.75-24.21c0-13.23,10.55-24.22,23.75-24.22"/>
      <path d="M252.24,196.53a18.35,18.35,0,0,1,1-6.14,13.23,13.23,0,0,1,2.73-4.62,11.87,11.87,0,0,1,4.12-2.92,13.15,13.15,0,0,1,5.23-1,9.88,9.88,0,0,1,4.91,1.11,14,14,0,0,1,3.22,2.39l-2,2.29a10.26,10.26,0,0,0-2.49-1.88,7.51,7.51,0,0,0-3.56-.75,9,9,0,0,0-3.89.8,7.93,7.93,0,0,0-2.94,2.29,10.57,10.57,0,0,0-1.88,3.61,15.57,15.57,0,0,0-.67,4.75,17.44,17.44,0,0,0,.62,4.82,10.87,10.87,0,0,0,1.82,3.65,7.87,7.87,0,0,0,2.94,2.33,9.34,9.34,0,0,0,4,.82,9.08,9.08,0,0,0,2.87-.45,6.19,6.19,0,0,0,2.23-1.23V199h-6v-3h9.29v11.93a10.61,10.61,0,0,1-3.65,2.35,13.39,13.39,0,0,1-5.12.93,12.84,12.84,0,0,1-5.14-1,11.19,11.19,0,0,1-4-2.87,13.35,13.35,0,0,1-2.64-4.62A19,19,0,0,1,252.24,196.53Z"/>
      <path d="M300.42,210.7l-6.82-12h-5.15v12h-3.58V182.35h8.86a18.46,18.46,0,0,1,4,.41,8.65,8.65,0,0,1,3.16,1.36,6.5,6.5,0,0,1,2.05,2.47,8.43,8.43,0,0,1,.74,3.71,7.85,7.85,0,0,1-1.73,5.32,8.89,8.89,0,0,1-4.63,2.72l7.18,12.36Zm-12-14.91h4.76a8.24,8.24,0,0,0,5.1-1.36,4.88,4.88,0,0,0,1.77-4.13,4.29,4.29,0,0,0-1.77-3.93,9.58,9.58,0,0,0-5.1-1.12h-4.76Z"/>
      <path d="M324.75,211.22a11.48,11.48,0,0,1-4.9-1,10.93,10.93,0,0,1-3.83-3,14,14,0,0,1-2.48-4.67,20,20,0,0,1-.89-6.15,19.6,19.6,0,0,1,.89-6.12A13.5,13.5,0,0,1,316,185.7a10.68,10.68,0,0,1,3.83-2.87,11.92,11.92,0,0,1,4.9-1,11.66,11.66,0,0,1,4.91,1,11.19,11.19,0,0,1,3.84,2.89,13.31,13.31,0,0,1,2.51,4.58,19.52,19.52,0,0,1,.89,6.08,20,20,0,0,1-.89,6.15,13.65,13.65,0,0,1-2.51,4.67,11.15,11.15,0,0,1-3.84,3A11.52,11.52,0,0,1,324.75,211.22Zm0-3.16a7.14,7.14,0,0,0,3.44-.82,7.62,7.62,0,0,0,2.63-2.35,11.48,11.48,0,0,0,1.71-3.68,17.77,17.77,0,0,0,.61-4.81,17.44,17.44,0,0,0-.61-4.76,10.73,10.73,0,0,0-1.71-3.58,7.69,7.69,0,0,0-2.63-2.27,7.77,7.77,0,0,0-6.87,0,7.72,7.72,0,0,0-2.64,2.27,11.13,11.13,0,0,0-1.71,3.58,17.46,17.46,0,0,0-.6,4.76,17.78,17.78,0,0,0,.6,4.81,11.92,11.92,0,0,0,1.71,3.68,7.65,7.65,0,0,0,2.64,2.35A7.13,7.13,0,0,0,324.75,208.06Z"/>
      <path d="M347.18,182.35h3.59v16.73a14.86,14.86,0,0,0,.52,4.27,7.42,7.42,0,0,0,1.4,2.77,5,5,0,0,0,2.1,1.49,7.34,7.34,0,0,0,2.59.45,7.54,7.54,0,0,0,2.64-.45,5.21,5.21,0,0,0,2.11-1.49,7.3,7.3,0,0,0,1.43-2.77,15.23,15.23,0,0,0,.52-4.27V182.35h3.45V199a18.65,18.65,0,0,1-.77,5.75,10.29,10.29,0,0,1-2.14,3.78,7.84,7.84,0,0,1-3.22,2.07,12.45,12.45,0,0,1-4,.63,12.62,12.62,0,0,1-4-.63,7.72,7.72,0,0,1-3.24-2.07,10.15,10.15,0,0,1-2.14-3.78,18.33,18.33,0,0,1-.78-5.75Z"/>
      <path d="M379.5,182.35h8.69a20.27,20.27,0,0,1,4.28.43,8.86,8.86,0,0,1,3.3,1.41,6.45,6.45,0,0,1,2.12,2.57,10.44,10.44,0,0,1,0,7.82,7.17,7.17,0,0,1-2.14,2.72,9.43,9.43,0,0,1-3.3,1.62,15.33,15.33,0,0,1-4.26.54h-5.1V210.7H379.5Zm8.26,14.18c2.48,0,4.31-.47,5.51-1.41a5.28,5.28,0,0,0,1.79-4.43q0-3.06-1.81-4.25a10.08,10.08,0,0,0-5.49-1.19h-4.67v11.28Z"/>
    </svg>
  );
}
