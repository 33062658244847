import { CircularProgress, makeStyles } from "@material-ui/core";
import { fontWeight, height } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";



const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "66vh",
    zIndex: 3,
    paddingTop: 186,
    backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)",
    overflow: "hidden"

  },
  loadingBox: {
    marginTop: 30,
    position: "relative",
    display: "flex",


  },
  loadingCircleProgress: {
    margin: "auto",
    fill: theme.palette.secondary.main,


  },
  loadingNumber: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "3rem",
    position: "absolute",
    top: "25%",
    left: "0%",
    bottom: "45%",
    right: "0%",
    textAlign: "center",
    margin: "auto"
  },
  text: {
    color: "white",
    fontWeight: "lighter",
    fontSize: "30px",
    marginTop: "100px",
  },
  span: {
    color: theme.palette.primary.main,
  },
  startButton: {
    backgroundColor: "#FF1A68",
    color: "#ffff",
    width: 190,
    height: 45,
    marginTop: 52,
    marginBottom: 10,
    marginRight: "auto",
    marginLeft: "auto",
    fontSize: 17,
    fontWeight: "bold",
    borderRadius: 40,
    border: '0',
    cursor:'pointer'

}}
));

export const Loader = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("ui");

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loadingBox}>
        <CircularProgress
          color="secondary"
          variant="determinate"
          value={props.progression * 100}
          size={150}
          thickness={1.7}
          className={classes.loadingCircleProgress}
        />
        <p className={classes.loadingNumber}>{(props.progression * 100).toFixed(0) + "%"}</p>
      </div>
      {props.xrReady && props.isLoaded && <button className={classes.startButton} onClick={props.startbutton}>Get Started</button>}
      <p className={classes.text}>We are loading the virtual environment,<span className={classes.span}> this won't take too long</span></p>
    </div>
  );
};
