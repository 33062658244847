/* eslint-disable max-len */

import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface PlayPauseProps extends SvgIconProps {
  playing: boolean;
}

export function PlayPause(props: PlayPauseProps): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 55 54"} {...props}>
      <defs>
        <filter id="ene5653spa" width="156.2%" height="156.2%" x="-28.1%" y="-28.1%" filterUnits="objectBoundingBox">
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="f107" colorInterpolationFilters="sRGB">
          <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0 " result="result7" />
          <feGaussianBlur result="result6" stdDeviation="5" />
          <feComposite in2="result7" operator="atop" in="result6" result="result91" />
          <feComposite operator="in" in2="result91" />
        </filter>
      </defs>
      {
        props.playing
          ? <g fill="none" fillRule="evenodd">
            <g fill="#FFF" fillRule="nonzero">
              <g>
                <g>
                  <g>
                    <g
                      filter="url(#ene5653spa)"
                      transform="translate(-1647.000000, -189.000000) translate(1555.000000, 36.000000) translate(55.762712, 161.288136) translate(-0.000000, 0.000000) translate(45.141243, 0.000000)">
                      <path d="M18.694 37.388C8.369 37.388 0 29.018 0 18.694 0 8.369 8.37 0 18.694 0s18.694 8.37 18.694 18.694c0 4.958-1.97 9.713-5.476 13.218-3.505 3.506-8.26 5.476-13.218 5.476zm0-33.99c-8.447 0-15.295 6.849-15.295 15.296s6.848 15.295 15.295 15.295 15.295-6.848 15.295-15.295S27.14 3.399 18.694 3.399z" />
                      <path d="M15.295 27.191c-.939 0-1.7-.76-1.7-1.7V11.897c0-.939.761-1.7 1.7-1.7.938 0 1.7.761 1.7 1.7v13.596c0 .938-.762 1.699-1.7 1.699zM22.093 27.191c-.939 0-1.7-.76-1.7-1.7V11.897c0-.939.761-1.7 1.7-1.7.938 0 1.7.761 1.7 1.7v13.596c0 .938-.762 1.699-1.7 1.699z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          : <g transform="scale(1.3 1.3) translate(0 -2)">
            <g
              fill="#000000"
              fillRule="evenodd"
              opacity="0.94"
              className=""
              filter="url(#f107)
              "id="svg_7" fillOpacity="1">
              <g
                fill="#000000"
                fillRule="nonzero"
                id="svg_9"
                opacity="0.94"
                filter="url(#f107)"
                fillOpacity="1"
              >
                <g id="svg_3" opacity="0.94" filter="url(#f107)" fill="#000000" fillOpacity="1">
                  <g id="svg_4" opacity="0.94" filter="url(#f107)" fill="#000000" fillOpacity="1">
                    <path
                      d="M-1634.8625158277155,-137.51795708030463 C-1642.6385158277153,-137.51795708030463 -1648.9425158277154,-143.82195708030463 -1648.9425158277154,-151.5979570803046 S-1642.6385158277153,-165.67795708030462 -1634.8625158277155,-165.67795708030462 s14.08,6.304 14.08,14.08 c0,3.734 -1.483,7.316 -4.124,9.956 c-2.64,2.64 -6.222,4.124 -9.956,4.124 zm0,-25.6 c-6.362,0 -11.52,5.158 -11.52,11.52 c0,6.362 5.158,11.52 11.52,11.52 c6.362,0 11.52,-5.158 11.52,-11.52 c0,-6.362 -5.158,-11.52 -11.52,-11.52 z"
                      transform="translate(1583,50) translate(76,126)"
                      id="svg_5"
                      opacity="0.94"
                      filter="url(#f107)"
                      fill="#000000"
                      fillOpacity="1" />
                    <path
                      d="M-1638.7025158277154,-143.91795708030463 c-0.2160000000000002,0.003000000000000007 -0.4290000000000003,-0.05500000000000005 -0.6140000000000008,-0.16600000000000026 c-0.4080000000000003,-0.22300000000000025 -0.663000000000001,-0.6500000000000009 -0.666000000000001,-1.114 V-157.99795708030462 c0,-0.466 0.251,-0.8950000000000009 0.658000000000001,-1.121 c0.4080000000000003,-0.22700000000000026 0.905,-0.2140000000000002 1.3,0.03300000000000005 l10.24,6.4 c0.3770000000000004,0.233 0.6060000000000006,0.6450000000000009 0.6060000000000006,1.088 c0,0.4430000000000004 -0.22900000000000026,0.8550000000000006 -0.6060000000000006,1.088 l-10.24,6.4 c-0.203,0.126 -0.4380000000000004,0.19300000000000025 -0.678000000000001,0.19200000000000025 zm1.28,-11.776 v8.192 l6.54,-4.096 l-6.54,-4.096 z" transform="translate(1583,50) translate(76,126) " id="svg_6" opacity="0.94" filter="url(#f107)" fill="#000000" fillOpacity="1" />
                  </g>
                </g>
              </g>
            </g><g fill="none" fillRule="evenodd" opacity="1" id="svg_1" className="selected" filter="">
              <g fill="#FFF" fillRule="nonzero" id="svg_2" opacity="1" filter="">
                <g id="svg_3" opacity="1" filter="">
                  <g id="svg_4" opacity="1" filter="">
                    <path d="M-1637.3931281325222,-139.39550817400217 C-1645.169128132522,-139.39550817400217 -1651.473128132522,-145.69950817400218 -1651.473128132522,-153.47550817400216 S-1645.169128132522,-167.55550817400217 -1637.3931281325222,-167.55550817400217 s14.08,6.304 14.08,14.08 c0,3.734 -1.483,7.316 -4.124,9.956 c-2.64,2.64 -6.222,4.124 -9.956,4.124 zm0,-25.6 c-6.362,0 -11.52,5.158 -11.52,11.52 c0,6.362 5.158,11.52 11.52,11.52 c6.362,0 11.52,-5.158 11.52,-11.52 c0,-6.362 -5.158,-11.52 -11.52,-11.52 z"
                      transform="translate(1583,50) translate(76,126)"
                      id="svg_5"
                      opacity="1"
                      filter=""
                    />
                    <path
                      d="M-1641.233128132522,-145.79550817400218 c-0.21600000000000022,0.003000000000000008 -0.4290000000000003,-0.055000000000000056 -0.6140000000000009,-0.1660000000000003 c-0.4080000000000003,-0.22300000000000028 -0.6630000000000011,-0.650000000000001 -0.6660000000000011,-1.114 V-159.87550817400216 c0,-0.466 0.251,-0.895000000000001 0.6580000000000011,-1.121 c0.4080000000000003,-0.22700000000000028 0.905,-0.21400000000000022 1.3,0.03300000000000006 l10.24,6.4 c0.37700000000000045,0.233 0.6060000000000006,0.645000000000001 0.6060000000000006,1.088 c0,0.44300000000000045 -0.2290000000000003,0.8550000000000006 -0.6060000000000006,1.088 l-10.24,6.4 c-0.203,0.126 -0.43800000000000044,0.19300000000000028 -0.6780000000000012,0.19200000000000028 zm1.28,-11.776 v8.192 l6.54,-4.096 l-6.54,-4.096 z"
                      transform="translate(1583,50) translate(76,126) "
                      id="svg_6"
                      opacity="1"
                      filter="" />
                  </g>
                </g>
              </g>
            </g>
          </g>
      }
    </SvgIcon>
  );
}
