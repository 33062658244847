import React from "react";
import './Navbar.css'
import { useAuth } from "../../context/authContext";


export default function Navbar(props) {
    const { state, dispatch } = useAuth();
    const signOut = () => dispatch({ type: 'removePasscode' });
    return (
        <div className="navbar-comp">
            <section className="navbar-parent">
                <div className="heading">
                    <h1>ocado <span className="heading-span">ISF</span></h1>
                </div>
                <div className="links">
                    {props.started && <a style={{display: props.showButton? "inline" : "none"}} onClick={props.hideNarrator}>{props.showNarrator? "Hide Narrator" : "Show Narrator"}</a>}
                    <a onClick={() => signOut()}>Sign Out</a>
                </div>
            </section>
        </div>
    )
}