import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Slide from "../../components/Slide/Slide";
import VideoCircle from "../../components/VideoCircle/VideoCircle";
import "./WelcomePage.css";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { width } from "@mui/system";
import instruction1 from "../../assets/images/instruction1.png";
import instruction2 from "../../assets/images/instruction2.png";
import '../../components/Footer/Footer.css';


const TITLE = "Please listen to the full introduction before you get started.";
const DESCRIPTION = "This will only take 5mins and we will cover how to use the experience and what to expect from this Ocado in Store Fulfillment session.";
const useStyles = makeStyles(() => ({
  title: {
    fontSize: 75,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    paddingBottom: 0,
    lineHeight: 1.3

  },
  subtitle: {
    fontSize: 75,
    fontWeight: "bold",
    color: "#00bbf4",
    textAlign: "center",
    lineHeight: 1.3
  },
  startButton: {
    backgroundColor: "#FF1A68",
    color: "#ffff",
    width: 190,
    height: 45,
    marginTop: 52,
    marginBottom: 10,
    marginRight: "auto",
    marginLeft: "auto",
    fontSize: 17,
    fontWeight: "bold",
    borderRadius: 40,
    border: '0',
    cursor: 'pointer'
  },
  icon: {
    height: "64px",
    width: "89px",
  }
}));

export default function Welcome(props) {
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const { t: tUnity } = useTranslation("unity");
  
  return (
    <div className="app-parent">
      <Navbar showButton={false}/>
      <div class="welcome">
        <Typography className={classes.title} align="center">
          ocado
        </Typography>
        <Typography className={classes.subtitle} align="right">
          In Store Fulfilment
        </Typography>
        <div>
          <Link to="/">
            <button className={classes.startButton}>Get Started</button>
          </Link>
        </div>
        {/* <Slide title={TITLE} description={DESCRIPTION}  /> */}
        <div className="instructions">
          <img className="instructionImg" src={instruction1} alt="" />
          <img className="instructionImg" style={{marginLeft: "50px"}} src={instruction2} alt="" />
        </div>
      </div>
      <div className='footer-comp'>
            <section className="footer">
            </section>
        </div>
      
    </div>
  );
}
