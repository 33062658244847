import React from "react";
import { useNavigate } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from "./components/Footer/Footer";
import { Loader } from "./components/Loader/Loader";
import XRContainer from "./components/XRContainer/XRContainer";
import { UnityContext } from "react-unity-webgl";
import VideoCircle from "./components/VideoCircle/VideoCircle";
import { useState, useEffect } from 'react';
import { UNITY } from './constants'
import Canvasvideo from "./components/CanvasVideo/Canvasvideo";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const uc = new UnityContext({
  loaderUrl: "ISFBuild/Build/ISFBuild.loader.js",
  dataUrl: "ISFBuild/Build/ISFBuild.data.unityweb",
  frameworkUrl: "ISFBuild/Build/ISFBuild.framework.js.unityweb",
  codeUrl: "ISFBuild/Build/ISFBuild.wasm.unityweb",
});


const totalsteps = 8;

let stepNumber = 0;

export default function App() {

  const [stepProgress, setStepProgress] = useState(0);


  const [buttonState, setButtonState] = useState(true);
  const [nextButtonName, setNextButtonName] = useState("Next Button");
  const [backButtonState, setBackButtonState] = useState(true);
  const [vimeoURL, setVimeoURL] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [xrReady, setXRReady] = useState(false);
  const [progression, setProgression] = useState(0);
  const [showNarrator, setShowNarrator] = useState(true);
  const [started, setStarted] = useState(false);
  const [canvasVideo, setCanvasVideo] = useState(true);
  const [clicked, setclicked] = useState(false);
  const [narratorVideo, setNarratorVideo] = useState(true);
  const [disableCanvasVideo, setDisableCanvasVideo] = useState(true);



  let navigate = useNavigate();

  useEffect(function () {
    uc.on("ButtonActivity", function (buttonValue,buttonName) {
      setButtonState(buttonValue);
      setNextButtonName(buttonName);
      console.log(buttonState);
    });

    uc.on("BackButtonActivity", function (buttonValue) {
      setBackButtonState(buttonValue)
      console.log(buttonState);
    });
    uc.on("URLActivity", function (urlValue) {
      setVimeoURL(urlValue);
      console.log(urlValue);
    });

    uc.on("SceneActivity", function () {
      navigate('/thankyou')

    });

    uc.on("CanvasVideoActivity", function () {
      setCanvasVideo(false);
    });
    uc.on(UNITY.LOADED, () => { setIsLoaded(true); });

    uc.on("OnXRReady", () => { setXRReady(true); });

    uc.on("progress", function (progression) {
      setProgression(progression);
    });

  }, []);

  function NextButton() {
    uc.send("StepManager", "LocationButton");
    if(stepNumber == 0)
    {
      setNarratorVideo(true);
    }
    stepNumber++;                  
    console.log("NextBUttonClicked");
    setStepProgress((stepNumber / totalsteps) * 100);
    console.log(`step number is ${stepNumber} and step progress is ${stepProgress}`);
    console.log(nextButtonName);
  }

  function BackButton() {
    uc.send("StepManager", "PreviousButton");
    // console.log("BackButtonClicked");
    // // stepProgress = (stepNumber / totalsteps) * 100;
    // setStepProgress(stepProgress);
    // console.log(stepNumber);
    // stepNumber--;
  }

  function changeVideoStatus(){
    setDisableCanvasVideo(false);
    uc.send("StepManager","VideoEnd");
    console.log("changeVieoStatus Called");

  }



  function HideNarrator() {
    if (showNarrator) {
      setShowNarrator(false);
    } else {
      setShowNarrator(true);
    }
  }

  function StartButton() {
    console.log("StartButtonClicked");
    setStarted(true);
  }


  function NarratorVidePlayPause() {
    console.log("Narrator Video Play Pause Clicked");
    if (narratorVideo === true) {
      setNarratorVideo(false);
    }
    else {
      setNarratorVideo(true)
    }
  }
  function handlePlay() {
    setclicked(true);
    setNarratorVideo(false);
  }




  return (
    <div className="app-parent">

      <Navbar hideNarrator={HideNarrator} showNarrator={showNarrator} started={started} showButton={true} />
      {
        !started && <Loader progression={progression} started={started} isLoaded={isLoaded} xrReady={xrReady} startbutton={StartButton}
        />
      }
      {
        started && canvasVideo && <Canvasvideo clicked={clicked} handlePlay={handlePlay} disabled={disableCanvasVideo}/>
      }
      {<XRContainer display={isLoaded && xrReady && started} uc={uc} />}
      <Footer btnfunction={NextButton} backbtnfunction={BackButton} progress={stepProgress} backButtonState={backButtonState} buttonState={buttonState} nextButtonName={nextButtonName} started={started} />
      {showNarrator && started && <VideoCircle changeVideoStatus={changeVideoStatus} url={vimeoURL} NarratorVidePlayPause={NarratorVidePlayPause} narratorVideo={narratorVideo} />}
    </div>
  );
}