import { LC } from "../constants";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import loginEnGB from './locales/enGB/login.json';
const resources = {
  "enGB": {
    "login": loginEnGB
  } 
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
    lng: LC.ENGB,
    fallbackLng: LC.ENGB,
    debug: true,
    interpolation: {
      escapeValue: false
    },  
    resources
  });

export default i18n;
