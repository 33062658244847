import React from "react";
import Unity from "react-unity-webgl";


export default function XRContainer(props){

    return(
        <div>
            {<Unity unityContext={props.uc} style={{
        height: "90vh",
        width: "100vw",
        display: props.display? 'block' : 'none'
      }} /> }
        </div>
    )
}