import { CircularProgress, Fade, Theme, makeStyles } from "@material-ui/core";
import { Mute } from "../Icons/Mute";
import { PlayPause } from "../Icons/PlayPause";
import ReactPlayer from "react-player/vimeo";
import { ResetVideo } from "../Icons/ResetVideo";
import clsx from "clsx";
import { useNarrationContext } from "../../context/useNarrationContext";
// import useNavigationContext from "../../hooks/useNavigationContext/useNavigationContext";
import { useTranslation } from "react-i18next";
import speaker from "../../assets/images/speaker.svg";
import replay from "../../assets/images/replay.svg";
import play from "../../assets/images/play.svg";
import pause from "../../assets/images/pause.svg";
import { Speaker } from "@material-ui/icons";
import { useEffect } from "react";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  videoCircle: {
    width: theme.narratorVideoWidthL,
    height: theme.narratorVideoWidthL,
    borderRadius: theme.narratorVideoWidthL / 2,
    top: 120,
    right: 40,
    position: "fixed",
    overflow: "hidden",
    opacity: 1,
    transition: "opacity 300ms"
  },
  withBorder: {
    borderStyle: "solid",
    borderColor: theme.darkColor,
    borderWidth: 4
  },
  innerCircle: {
    backgroundColor: "#edf4fc",
    width: theme.narratorVideoWidthL,
    height: theme.narratorVideoWidthL
  },
  reactPlayer: {
    position: "relative",
    opacity: 1,
    transition: "opacity 300ms",
    left: "-90px",
    top: "-80px"
  },
  VButton: {
    position: "absolute",
    zIndex: 1,
    cursor: "pointer",
    opacity: 0.9,
    height: "30px",
    width: "30px",
    top: "115px",
    filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .2))",
    "&:hover": {
      opacity: 1,
      filter: "drop-shadow(0px 1px 5px rgb(0 0 0 / .35))",
      top: "111px"
    }
  },
  resetVideo: {
    left: "12%"
  },
  playVideo: {
    left: "41%"
  },
  muteVideo: {
    right: "10%",
    // top: "62%"
  },
  progressIndicator: {
    position: "absolute",
    zIndex: 1,
    top: 70,
    left: (theme.narratorVideoWidthL / 2) - (60 / 2) - 5
  }
}));

const VideoCircle = (props) => {
  
  const [videoEnded, setVideoEnded] = useState(false);

  const isBrightSection = () => true;
  const classes = useStyles();
  const { t } = useTranslation("sections");
  const {
    isMuted,
    isPlaying,
    source,
    toggleMute,
    onReady,
    onPlay,
    onPause,
    onReset,
    onUpdate,
    isBuffering,
    setIsPlaying,
    onEnded,
    isEnded,
    narratorHidden
  } = useNarrationContext();


  useEffect(() =>{
    if(isEnded){
      setVideoEnded(true);
      console.log(`video ended: ${videoEnded}`);
      props.changeVideoStatus(); 
    }
  },[isEnded])
  
  

  const source2 = 'https://vimeo.com/581921116/126caff627';
  // const { isBrightSection } = useNavigationContext();

  let player;

  const handleReset = () => {
    onReset();
    player.seekTo(0);
  };

  const ref = (playerIn) => {
    player = playerIn;
  };
  
 

  return (
    <Fade in={true && !narratorHidden} timeout={500}>
      <div className={
        clsx({
          [classes.videoCircle]: true,
          [classes.withBorder]: isBrightSection()
        })
      }>
        <div className={classes.innerCircle}>
          {/* <ResetVideo onClick={handleReset} className={clsx(classes.VButton, classes.resetVideo)} /> */}

          <img src={replay} className={clsx(classes.VButton, classes.resetVideo)} onClick={handleReset} alt="" />
         
          <img src={props.narratorVideo? pause : play} className={clsx(classes.VButton, classes.playVideo)} onClick={props.NarratorVidePlayPause} alt="" />

        
          <img src={speaker} className={clsx(classes.VButton, classes.muteVideo)} style={{ opacity: isMuted ? 0.5 : 1 }} onClick={toggleMute} alt="" />

          {
            isBuffering
              ? <CircularProgress size={60} className={classes.progressIndicator} />
              : null
          }
          <ReactPlayer
            ref={ref}
            controls={false}
            playing={props.narratorVideo}
            loop={false}
            muted={isMuted}
            className={classes.reactPlayer}
            config={{
              playerOptions: {
                quality: "360p",
                dnt: true,
                maxHeight: 200
              }
            }}
            volume={0.9}
            width='200%'
            height='200%'
            onReady={onReady}
            onPlay={onPlay}
            onPause={onPause}
            onProgress={onUpdate}
            onEnded={onEnded}
            progressInterval={200}
            style={{
              opacity: isEnded ? 0.2 : 1,
              transition: "opacity 1s"
            }}
            url={props.url}
            quality="360p"
          />
          {/*  <ReactPlayer
            url={t(source)}
          
            }}
          /> */}
        </div>
      </div>
    </Fade>);
};

export default VideoCircle;
