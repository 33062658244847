import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { OcadoInputField } from "../../components/OcadoInputField/OcadaInputField"
import OcadoLogo from "../../components/Icons/OcadoLogo";
import OcadoLogoLarge from "../../components/Icons/OcadoLogoLarge";
import Typography from "@material-ui/core/Typography";
import alert from "../../assets/images/alert.png";
import cloud from "../../assets/images/cloud.png";
import globe from "../../assets/images/globe.png";
import laptop from "../../assets/images/laptop.png";
import { useAuth } from "../../context/authContext";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(() => ({
  title: {
    fontSize: 75,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",

  },
  titleWithColor: {
    fontSize: 75,
    fontWeight: "bold",
    color: "#00bbf4",
    textAlign: "center",
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  languageDropdown: {
    position: "absolute",
    left: 49,
    top: 54
  },
  ocadoLogo: {
    position: "absolute",
    right: 10,
    top: 25
  },
  loginContainer: {
    backgroundImage: "linear-gradient(to bottom, #012749 18%, #061727 100%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    zIndex: 3,
    paddingTop: 170
  },
  logo: {
    width: 800
  },
  passcodeContainer: {
    width: 347,
    marginTop: 45,
    marginBottom: 45,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  textBox: {
    width: 410,
    marginTop: 20,
    marginBottom: 20,
    display: "flex"
  },
  icon: {
    marginRight: 17,
    maxWidth: 43,
    height: "100%",
    objectFit: "contain"
  },
  noteText: {
    color: "#f4f4f4",
    fontSize: 14
  },
  loginButton: {
    width: 155,
    marginTop: 20,
    height: 48,
    borderRadius: 40,
    color: "#ffffff",
    fontSize: 14
  },
  forgetPasswordText: {
    marginTop: 0,
    display: "block",
    fontSize: 16,
    textAlign: "center"
  }
}));

export default function LoginPage() {


  const PASSWORD = process.env.REACT_APP_PASSCODE;
  const classes = useStyles();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [passcode, setPasscode] = useState("");
  const [email, setEmail] = useState("");
  const [authError, setAuthError] = useState(null);
  const { t } = useTranslation("login", { useSuspense: true });

  



  const handleSubmit = (e) => {
    e.preventDefault();
    if (passcode === PASSWORD) {
      dispatch({ type: 'setPasscode', payload: passcode })
      navigate('/welcome');
    } else {
      setAuthError({ message: 'Wrong passcode' })
    }
  };

  useEffect(() => {
    if (state === PASSWORD) {
      navigate('/');
    }
  }, [])

  return (
    <div className={classes.loginContainer}>
      <div className={classes.ocadoLogo}>
        <OcadoLogo />
      </div>
      <h1 paragraph={false} className={classes.title} align="center">
          <span>ocado</span> <span className={classes.titleWithColor}> ISF </span>
        </h1>
      <form onSubmit={handleSubmit}>
        <div className={classes.passcodeContainer}>
          <OcadoInputField
            fullWidth
            id="input-passcode"
            onChange={(e) =>
              setPasscode(e.target.value)
            }
            
            placeholder={t("passcode")}
            autoComplete="current-password"
            

          />
          <Button
            className={classes.loginButton}
            disableElevation
            variant="contained"
            color="secondary"
            type="submit"
          >
            {t("login")}
          </Button>
          <div>
          </div>
          {authError && (
            <Typography variant="caption" className={classes.errorMessage}>
              <ErrorOutlineIcon />
              {authError.message}
            </Typography>
          )}
        </div>
      </form>
      <div className={classes.textBox}>
        <img className={classes.icon} src={alert} />
        <Typography className={classes.noteText}>
           {t("login_note_a")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={laptop} />
        <Typography className={classes.noteText}>
          {t("login_note_b")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={globe} />
        <Typography className={classes.noteText}>
          {t("login_note_c")}
        </Typography>
      </div>
      <div className={classes.textBox}>
        <img className={classes.icon} src={cloud} />
        <Typography className={classes.noteText}>
          {t("login_note_d")}
        </Typography>
      </div>
    </div>);
}
