import React from "react";
import './Progressbar.css'
export default function Progressbar(props) {

    const childdiv = {
        width: `${props.progress}%`
    }
    return (
        <div className="bar-parent">
            <div className="bar-child" style={{width : childdiv.width}}></div>        
        </div>
    )
}