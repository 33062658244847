import React from 'react';
import './Footer.css';
import Progressbar from '../Progressbar/Progressbar';

export default function Footer(props) {
    return (
        <div className='footer-comp'>
            <section className="footer">
                <div className='progress-parent'>
                    {/* <div className='pbar-parent'>
                        <span>Section Progress</span>
                        <Progressbar progress = {props.progress}/>
                    </div> */}
                   {props.started && <div className='pbar-parent'>
                        <span>Total Progress</span>
                       <Progressbar progress = {props.progress}/>
                    </div>}
                    {/* <span>Estimated Time</span> */}

                    {
                        props.started && <button className='next-button' onClick={props.btnfunction} disabled = {!props.buttonState}>{props.nextButtonName}</button>

                    }
                    {
                        props.started && <button className='back-button' onClick={props.backbtnfunction} disabled = {!props.backButtonState}>Replay Step</button>

                    }
                </div>
            </section>
        </div>
    )
}
