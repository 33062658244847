// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/bggradient.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".welcome {\n    display: flex;\n    flex-direction: column;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-size: cover;\n    height: 85%;\n    width: 100%;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.instructions {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    margin-top: 150px;\n}\n\n.instructionImg {\n    width: 500px;\n    height: auto;\n\n}", "",{"version":3,"sources":["webpack://./src/pages/WelcomePage/WelcomePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6DAA+D;IAC/D,sBAAsB;IACtB,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;;AAEhB","sourcesContent":[".welcome {\n    display: flex;\n    flex-direction: column;\n    background: url('../../assets/images/bggradient.png') no-repeat;\n    background-size: cover;\n    height: 85%;\n    width: 100%;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n}\n\n.instructions {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    margin-top: 150px;\n}\n\n.instructionImg {\n    width: 500px;\n    height: auto;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
