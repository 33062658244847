import { createContext, useState, useContext } from 'react';
import { EVENTS } from "../constants";
import EventEmitter from "events";

const NarrationContext = createContext(null);

function NarrationProvider({ children }) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [source, setSource] = useState("");
    const [isFinished, setIsFinished] = useState(false);
    const [percentPlayed, setPercentPlayed] = useState(0);
    const [updateProgress, setUpdateProgress] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
    const isReady = false;
    const narrationEvents = new EventEmitter();
    const [narratorHidden, setNarratorHidden] = useState(false);
  
    const pause = () => {
      setIsPlaying(false);
    };
  
    const onReset = () => {
      narrationEvents.emit(EVENTS.NARRATION_RESET);
      setPercentPlayed(0);
      setIsFinished(false);
    };
  
    const toggleMute = () => {
      setIsMuted(!isMuted);
    };
  
    const onUpdate = (e) => {
      if (updateProgress) {
        setPercentPlayed(e.played);
      } else {
        setPercentPlayed(0);
      }
    };
  
    const onReady = () => {
      setIsEnded(false);
      setIsBuffering(false);
      setPercentPlayed(0);
      setIsPlaying(true);
      setTimeout(() => setUpdateProgress(true), 1000);
    };
  
    const onPlay = () => {
      setIsEnded(false);
      narrationEvents.emit(EVENTS.NARRATION_PLAYED);
    };
  
    const onPause = () => {
      narrationEvents.emit(EVENTS.NARRATION_PAUSED);
    };
  
    const onEnded = () => {
      setIsEnded(true);
    };
  
    const setSourceWrapper = (src) => {
      setIsBuffering(true);
      setSource(src);
      setUpdateProgress(false);
    };
  
    const toggleNarration = () => {
      setNarratorHidden(prevNarratorHidden => !prevNarratorHidden);
    };
  
    return (
      <NarrationContext.Provider
        value={{
          setSourceWrapper,
          pause,
          onReset,
          setIsPlaying,
          toggleMute,
          onReady,
          onPlay,
          onPause,
          onUpdate,
          onEnded,
          setPercentPlayed,
          narrationEvents,
          isMuted,
          isPlaying,
          isFinished,
          isBuffering,
          isReady,
          source,
          isEnded,
          percentPlayed,
          toggleNarration,
          narratorHidden
        }}
      >
        {children}
      </NarrationContext.Provider>

    )
}

function useNarrationContext() {
    const context = useContext(NarrationContext);
    if (context ===  undefined) {
        throw new Error('useNarrationContext must be used within a NarrationProvider');
    }
    return context;
}

export { NarrationProvider, useNarrationContext };
