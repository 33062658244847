import ReactPlayer from "react-player";
import React from "react";
import './Canvasvideo.css';
import { useState, useEffect } from 'react';
import play from "../../assets/images/playButton.png";
import disabledPlay from "../../assets/images/disabledPlay.png";
import { height } from "@mui/system";

export default function (props) {


    return (
        <div className="videocontainer">
            <div className="videoparent">
                {
                    !props.clicked && <button className="videoplaybutton" onClick={props.handlePlay} disabled={props.disabled} style={{
                        backgroundImage: props.disabled? `url(${disabledPlay})` : `url(${play})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}>
                    </button>}
                {props.clicked &&
                    <ReactPlayer className="video" url="https://vimeo.com/710743850/d5f33777ca" controls={true} width="100%" height="100%" playing={props.clicked} />
                }
              
            </div>
        </div>

    )
}
